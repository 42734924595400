import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import Layout from "../components/Layout";
import ContactForm from "../pages/contact/form";

export const ContactPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <section className="home-hero">
      <InView threshold={0.2}>
        {({ ref, inView }) => (
          <motion.div
            ref={ref}
            initial={{ opacity: 0, transform: "translate(-50px)" }}
            animate={
              inView
                ? { opacity: 1, transform: "translate(0px)" }
                : { opacity: 1 }
            }
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
            className="ontopyo"
          >
            <h1
              data-scroll
              data-scroll-direction="horizontal"
              data-scroll-speed="2"
              data-scroll-position="top"
              data-scroll-delay="0.2"
            >
              CONTACT US
            </h1>
          </motion.div>
        )}
      </InView>

      <div className="scroll-line">
        <div className="center" />

        <div data-scroll data-scroll-speed="-1" className="marker" />
      </div>

      <div className="video-container">
        <div className="video-overlay" />
        <video loop autoPlay muted>
          <source src="../img/ex-hero.mp4" type="video/mp4" />
        </video>
      </div>
    </section>
    <div className="contact-heli">
      <div className="intro-heli-grid">
      <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="item"
            >
              <h2 data-scroll data-scroll-speed="2">
              FIND US
              </h2>
              <p data-scroll data-scroll-speed="1">
              Hangar 17, Stapleford Aerodrome, Stapleford Tawney, Essex, RM4 1SJ
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="item"
            >
              <h2 data-scroll data-scroll-speed="2">
                CONTACT US
              </h2>
              <p data-scroll data-scroll-speed="1">
              0800 368 8975
              contact@excelhelicopters.co.uk
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="item"
            >
              <h2 data-scroll data-scroll-speed="2">
              {/* AOC No. GB 2184 */}
              </h2>
              <p data-scroll data-scroll-speed="1">
              VAT number: 773 6059 08<br/>
              Company number: 411 0313
              </p>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="block-heli">
      <InView threshold={0.2}>
        {({ ref, inView }) => (
          <motion.div
            ref={ref}
            initial={{ opacity: 0 }}
            animate={inView ? { opacity: 1 } : { opacity: 1 }}
            transition={{
              duration: 0.25,
              delay: 0.1,
            }}
            className="heli-block"
          >
            <ContactForm />
          </motion.div>
        )}
      </InView>
    </div>
  </div>
);

ContactPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ContactPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            text
          }
          heading
          description
        }
      }
    }
  }
`;
